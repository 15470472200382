import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/reevesdotnl/reevesdotnl/src/templates/pdf-view.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PublishDate = makeShortcode("PublishDate");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The next opportunity to follow this course will be at the SAGA meeting in
Skukuza, October 2013  (see 'Read more', below). The tenth running of the
`}<strong parentName="p">{`African Geodynamics`}</strong>{` course was held during  the 24th Colloquium of African
Geology in Addis Abeba, January 11 and 12, and generated  considerable interest
The course is an attempt to distil the experience of more than 20 years of
plate tectonic modelling into a global picture of the evolution of the African
continent, its shelves,  rifts and surrounding oceans throughout Phanerozoic
time. The incomplete knowledge of Africa's  geology is supplemented with more
than 40 years experience with regional geophysical mapping in  Africa, India and
Australia, as well as from the record of growth of the surrounding oceans. `}</p>
    <p>{`Details of the programme may be found at this webpage: `}<a parentName="p" {...{
        "href": "/"
      }}>{`African Geodynamics`}</a></p>
    <p>{`The next running of the course will take place October 4-5, immediately prior to
the 13th Biennial  Conference of SAGA (South African Geophysicists Association)
scheduled for Skukuza in the Kruger  National Park 6-8 October 2013. Further
details and the chance to enrol may be found on the  SAGA website
(`}<a parentName="p" {...{
        "href": "http://www.saga-aem2013.co.za/"
      }}>{`http://www.saga-aem2013.co.za/`}</a>{`). `}</p>
    <PublishDate mdxType="PublishDate" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      